import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    userDetail: {
      username:'',
      email_id: ''
    }
  },
  reducers: {
    toggle(state, action) {
      state.isAuth = action.payload;
    },
    setUserDetail(state, action){
      state.userDetail = action.payload;
    }
  },
});

export const authActions = authSlice.actions;
export default authSlice;
