import React from "react";
import { Icon } from "@material-ui/core";
import classes from "./ContactUs.module.css";
import { useSelector } from "react-redux";
import cx from 'classnames'
import { AppDispatch, RootState } from '../../store/index'


interface ContactUsType {
  theme: string
}

const ContactUs: React.FC<ContactUsType> = (props) => {

  const Theme = useSelector((state: RootState) => state.theme.Theme);
  let MessageStyle;
  let icon;
  let ContactUsStyle
  if (Theme === 'dark' && props.theme === 'dark') {
    MessageStyle = cx(classes.Message, classes.Dark);
    icon = <Icon className={classes.Icon} style={{ color: "white" }}>help_outline</Icon>
    ContactUsStyle = classes.ContactUsDark;
  }
  else {
    MessageStyle = cx(classes.Message, classes.Light);
    icon = <Icon className={classes.Icon} style={{ color: "#526788" }}>help_outline</Icon>
    ContactUsStyle = classes.ContactUsLight;
  }

  return (
    <div className={ContactUsStyle}>
      <div className={MessageStyle}>Contact Us</div>
      {icon}
    </div>
  );
};

export default ContactUs;
