import React from "react";
import { useState } from "react";
import classes from './Timeseries.module.css'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ReactApexChart from "react-apexcharts";
import Loader from "react-loader-spinner";

const TimeseriesGraph: React.FC = () => {

    const graphData: any = useSelector((state: RootState) => state.camera.graphData);
    const Axistype: "datetime" = "datetime"

    const [state, setState] = useState({
        options_1: {
            chart: {
                foreColor: 'white',
                id: 'chart1',
                group: "group",
                zoom: {
                    enabled: true
                },
                animations: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                showForSingleSeries: false
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            colors: ['#FFFF00'],
            xaxis: {
                type: Axistype
            },
            tooltip: {
                shared: false,
                x: {
                    format: 'dd MMM - HH : mm '
                }
            }
        },

        options_2: {
            chart: {
                foreColor: 'white',
                id: 'chart2',
                group: "group",
                zoom: {
                    enabled: true
                },
                animations: {
                    enabled: false
                },
            },
            colors: ['#FFFF00'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                showForSingleSeries: false
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            xaxis: {
                type: Axistype
            },
            tooltip: {
                shared: false,
                x: {
                    format: 'dd MMM - HH : mm '
                }
            }
        },
        options_3: {
            chart: {
                foreColor: 'white',
                id: 'chart3',
                group: "group",
                zoom: {
                    enabled: true
                },
                animations: {
                    enabled: false
                },
            },
            colors: ['#FFFF00'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                showForSingleSeries: false
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            xaxis: {
                type: Axistype
            },
            tooltip: {
                shared: false,
                x: {
                    format: 'dd MMM - HH : mm '
                }
            }
        },
    });

    if (Object.keys(graphData).length > 0) {
        return (
            <div className={classes.Graph}>
                <div className={classes.Graph1}>
                    <div className={classes.Title}>Total Vehicle Count</div>
                    <ReactApexChart options={state.options_1}
                        series={[{
                            name: "Vehicle Count",
                            data: graphData['vehicle_count']
                        }]}
                        type="area" height="100%" />

                </div>
                <div className={classes.Graph1}>
                    <div className={classes.Title}>Average Signal Waiting Time</div>
                    <ReactApexChart options={state.options_2}
                        series={[{
                            name: "Average Signal Waiting Time",
                            data: graphData['average_waiting_time']
                        }]}
                        type="area" height="100%" />
                </div>

                <div className={classes.Graph1}>
                    <div className={classes.Title}>Traffic Jam Count</div>
                    <ReactApexChart options={state.options_2}
                        series={[{
                            name: "Traffic Jam Count",
                            data: graphData['traffic_jam_count']
                        }]}
                        type="area" height="100%" />
                </div>

            </div>)
    }

    else {
        return <div className={classes.Loader}>
            <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={20000}
            />
        </div>
    }
}
export default TimeseriesGraph;
