import React from "react";
import classes from "./ImageMessage.module.css";
import { Grid } from "@material-ui/core";
import { AppDispatch, RootState } from '../../store/index'
import { useSelector } from "react-redux";
import cx from 'classnames'

interface MessageType {
    messageIcon: string;
    messageText: string;
    next: boolean;
}

const ImageMessage: React.FC<MessageType> = (props) => {

    const Theme = useSelector((state: RootState) => state.theme.Theme);
    let MessageStyle;
    let DescriptionStyle;

    if (Theme === "dark") {
        MessageStyle = cx(classes.MessageText, classes.DarkMessage)
        DescriptionStyle = cx(classes.Description, classes.DarkDescription)
    }
    else {
        MessageStyle = cx(classes.MessageText, classes.LightMessage)
        DescriptionStyle = cx(classes.Description, classes.LightDescription)
    }

    let next;



    return (
        <Grid container className={classes.ImageMessage}>
            <Grid xs={2} item>
                <img className={classes.Icon} src={props.messageIcon}></img>
            </Grid>
            <Grid xs={10} item>
                <div className={MessageStyle}>{props.messageText}</div>
            </Grid>
        </Grid>
    );
};

export default ImageMessage;
