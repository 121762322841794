import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const jurisdictionsSlice = createSlice({
    name: "jurisdictions",
    initialState: {
        jurisdictions: {},
        selectedJurisdiction: {}
    },
    reducers: {
        setJurisdictions(state, action) {
            state.jurisdictions = action.payload;
        },
        setSelectedJurisdiction(state, action) {
            state.selectedJurisdiction = action.payload;
        },
    },
});

export const jurisdictionsActions = jurisdictionsSlice.actions;
export default jurisdictionsSlice;
