import React from "react";
import { Icon } from "@material-ui/core";
import classes from "./HelpIcon.module.css";
import { useSelector } from "react-redux";
import cx from 'classnames'
import { AppDispatch, RootState } from '../../store/index'

interface HelpIconType {
  theme: string
}

const HelpIcon: React.FC<HelpIconType> = (props) => {

  const Theme = useSelector((state: RootState) => state.theme.Theme);
  let MessageStyle;
  let icon;

  if (Theme === 'dark' && props.theme === 'dark') {
    MessageStyle = cx(classes.Message, classes.Dark);
    icon = <Icon style={{ color: "white" }}>support</Icon>
  }
  else {
    MessageStyle = cx(classes.Message, classes.Light);
    icon = <Icon style={{ color: "#526788" }}>support</Icon>
  }
  return (
    <div>
      <div className={MessageStyle}>Help !</div>
      {icon}
    </div>
  );
};

export default HelpIcon;
