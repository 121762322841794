import React, { useEffect } from "react";
import classes from "./TopPanel.module.css";
import { logoutUser } from "../../containers/Auth";
import { useHistory } from "react-router-dom";
import { authActions } from "../../store/auth-slice";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from "react-redux";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from "../Helpers/HelpIcon";
import ContactUs from "../Helpers/ContactUs";

import { Lock } from '@material-ui/icons';
import { AppDispatch, RootState } from '../../store/index'
import { useSelector } from "react-redux";
import cx from 'classnames'
import { Icon } from "@material-ui/core";
import { getUserDetail } from "../../store/auth-actions";
import Eflag from '../../assets/eflag2.png'
import TrafficJams from "../TrafficJam/TrafficJams";

const TopPanel: React.FC = (props) => {

  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();


  const handleLogout = () => {
    logoutUser();

    dispatch(authActions.toggle(false));
    history.push("/login");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen;
    var cancelFullScreen = doc.exitFullscreen;

    if (!doc.fullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }


  useEffect(() => {
    dispatch(getUserDetail());
  }, [])

  const Theme = useSelector((state: RootState) => state.theme.Theme);
  const userDetail = useSelector((state: RootState) => state.auth.userDetail);

  let UserStyle;
  let EmailStyle
  let DarkModeStyle;
  let LightModeStyle;
  let ExpandStyle;

  if (Theme === "dark") {
    UserStyle = cx(classes.User, classes.Dark)
    EmailStyle = cx(classes.Email, classes.Dark)
    DarkModeStyle = cx(classes.DarkMode, classes.DarkModeSelected)
    LightModeStyle = classes.LightMode;
    ExpandStyle = classes.LightExpand;

  }
  else {
    UserStyle = cx(classes.User, classes.Light)
    EmailStyle = cx(classes.Email, classes.Light)
    LightModeStyle = cx(classes.LightMode, classes.LightModeSelected)
    DarkModeStyle = classes.DarkMode;
    ExpandStyle = classes.DarkExpand;
  }


  return (
    <div className={classes.TopPanel}>
      <div className={classes.Row1}>
        <img className={classes.Logo1} src={Eflag}></img>

      </div>
      <div className={classes.Row2}>
        <HelpIcon theme="dark"></HelpIcon>
      </div>
      <div className={classes.Row3}>
        <ContactUs theme="dark"></ContactUs>
      </div>
      <div className={classes.Row4}>
        {/* <div className={DarkModeStyle} onClick={() => { dispatch(themeActions.setTheme("dark")) }}></div>
        <div className={LightModeStyle} onClick={() => { dispatch(themeActions.setTheme("light")) }}></div> */}
        <Icon className={ExpandStyle} style={{ fontSize: "4vh" }} onClick={() => { toggleFullScreen() }}>aspect_ratio_icon</Icon>
        <Icon className={ExpandStyle} style={{ fontSize: "4vh" }} onClick={() => { history.push('/') }}>dashboard</Icon>
      </div>
      <div className={classes.Row5}>
        <div className={UserStyle}>{userDetail.username}</div>
        <div className={EmailStyle}>{userDetail.email_id}</div>
      </div>
      <div className={classes.Row6}>
        <Icon
          className={classes.UserIcon}
          onClick={handleClick}
          style={{ fontSize: '3vh' }}
        >person</Icon>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem className={classes.MenuItem} onClick={handleLogout}>
            <ListItemIcon>
              <Lock fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Logout</Typography>
          </MenuItem>
        </Menu>
      </div>
      <TrafficJams />
    </div>
  );
};

export default TopPanel;
