import React, { useState } from "react";
import { AppDispatch, RootState } from '../store/index'
import { useSelector } from "react-redux";
import cx from 'classnames'
import classes from "./Layout.module.css";
import TopPanel from "../components/TopPanel/TopPanel";

const Layout: React.FC = (props) => {

  const Theme = useSelector((state: RootState) => state.theme.Theme);
  let ContainerStyle;
  let ContentPanelRowStyle;
  let TopPanelRowStyle;
  let CopyRightsStyle;

  if (Theme === "dark") {
    ContainerStyle = cx(classes.Container, classes.DarkBG)
    ContentPanelRowStyle = cx(classes.ContentPanelRow, classes.DarkBG, classes.DarkGradient);
    TopPanelRowStyle = cx(classes.TopPanelRow, classes.DarkBG);
    CopyRightsStyle = cx(classes.Copyrights, classes.DarkBG);
  }
  else {
    ContainerStyle = classes.Conatiner;
    ContentPanelRowStyle = cx(classes.ContentPanelRow, classes.LightBG);
    TopPanelRowStyle = classes.TopPanelRow;
    CopyRightsStyle = classes.Copyrights
  }


  return (
    <div className={classes.ContainerStyle}>
      <div className={TopPanelRowStyle}>
        <TopPanel></TopPanel>
      </div>
      <div className={ContentPanelRowStyle}>
        {props.children}
      </div>
      <div className={CopyRightsStyle}>
        © Copyright 2021. ParXsys MoBliT Systems Pvt. Ltd
      </div>
    </div>
  );
};

export default Layout;
