import React, { useState, useEffect } from "react";
import { Suspense } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { ping } from "./Auth";
import { authActions } from "../store/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import GetStarted from "../components/GetStarted/GetStarted";
import Loading from "../components/Helpers/Loading";
import Camera from "./Camera";

// import LoginHandler from "./LoginHandler";
// import Home from "./Home";
// import Junction from "./Junction";

const Home = React.lazy(() => import('./Home'));
const LoginHandler = React.lazy(() => import('./LoginHandler'));
const Junction = React.lazy(() => import('./Junction'));


const Routes: React.FC = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);

  const [pingStatus, setPingStatus] = useState(false);

  useEffect(() => {
    ping()
      .then((response) => {

        console.log(response);
        dispatch(authActions.toggle(true));

        setTimeout(() => {
          setPingStatus(true);
        }, 500)
      })
      .catch(() => {
        dispatch(authActions.toggle(false));
        setTimeout(() => {
          setPingStatus(true);
        }, 500)
      });
  }, []);

  if (pingStatus === false) {
    return (<Loading></Loading>)
  }
  else {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading></Loading>}>

          <Route exact path="/test">
            <Suspense fallback={<Loading></Loading>}>
              <Loading></Loading>
            </Suspense>
          </Route>

          <Route exact path="/login">
            <Suspense fallback={<Loading></Loading>}>
              <LoginHandler></LoginHandler>
            </Suspense>
          </Route>

          <Route exact path="/">
            {isAuth ?
              <Suspense fallback={<Loading></Loading>}>
                <Home></Home>
              </Suspense> : <Suspense fallback={<Loading></Loading>}>
                <LoginHandler></LoginHandler>
              </Suspense>}
          </Route>

          <Route exact path="/junction/:junction_id">
            {isAuth ?
              <Suspense fallback={<Loading></Loading>}>
                <Junction></Junction>
              </Suspense> : <Suspense fallback={<Loading></Loading>}>
                <LoginHandler></LoginHandler>
              </Suspense>}
          </Route>

          <Route exact path="/camera/:camera_id">
            <Suspense fallback={<Loading></Loading>}>
              {isAuth ?
                <Suspense fallback={<Loading></Loading>}>
                  <Camera></Camera>
                </Suspense> : <Suspense fallback={<Loading></Loading>}>
                  <LoginHandler></LoginHandler>
                </Suspense>}
            </Suspense>
          </Route>

        </Suspense>
      </BrowserRouter>
    );
  }
};

export default Routes;
