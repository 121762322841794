import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const graphSlice = createSlice({
  name: "graph",
  initialState: {
    data: [],
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

export const graphActions = graphSlice.actions;
export default graphSlice;
