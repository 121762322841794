import { devicesActions } from "./devices-slice"
import { authRequest } from "../containers/Auth"

import { DefaultEnv } from "../containers/Endpoints";
import { authActions } from "./auth-slice";

export const getUserDetail = () => {

    return (dispatch: any) => {

        const endpoint = DefaultEnv.UserPing

        authRequest.get(endpoint)
            .then((response) => {
                console.log('Ping', response.data)

                dispatch(authActions.setUserDetail({
                    'username': response.data.username,
                    'email_id': response.data.email_id
                  }));
                  
            })
            .catch((error) => console.log(error.response.data))
    }
}