import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const junctionsSlice = createSlice({
  name: "junctions",
  initialState: {
    junctions: {},
    selectedJunction: {},
  },
  reducers: {
    setJunctions(state, action) {
      state.junctions = action.payload;
    },
    setSelectedJunction(state, action) {
      state.selectedJunction = action.payload;
    },
  },
});

export const junctionsActions = junctionsSlice.actions;
export default junctionsSlice;
