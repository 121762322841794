
import BackgroundImage from "../../assets/loginbackground1.png";
import classes from './Loading.module.css'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loading = () => {
    return <div className={classes.Loading}>
        <img className={classes.Image} src={BackgroundImage}></img>
        <div className={classes.Loader}>
            <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={5000}
            />
        </div>

    </div>
}

export default Loading;