import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const trafficSlice = createSlice({
  name: "traffic",
  initialState: {
    traffic: undefined,
    signalWaitTime: undefined,
    top5Traffic: [],
    top5Signal: [],
    streamUrl: { url: '', camera: '' },
    trafficJam: undefined,
    vehiclesList: [],
  },
  reducers: {
    setTraffic(state, action) {
      state.traffic = action.payload;
    },
    setTop5Signal(state, action) {
      state.top5Signal = action.payload;
    },
    setTop5Traffic(state, action) {
      state.top5Traffic = action.payload;
    },
    setSignalWaitTime(state, action) {
      state.signalWaitTime = action.payload;
    },
    setStreamUrl(state, action) {
      state.streamUrl = action.payload;
    },
    setTrafficJam(state, action) {
      state.trafficJam = action.payload;
    },
    setVehiclesList(state, action) {
      state.vehiclesList = action.payload
    }
  },
});

export const trafficActions = trafficSlice.actions;
export default trafficSlice;
