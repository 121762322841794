import React, { useState, useRef, useMemo } from "react";
import Layout from "./Layout";
import classes from './Camera.module.css'
import TimeseriesGraph from "../components/Camera/TimeseriesGraph";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, getGraphData, getVideoData } from "../store/camera-actions";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../store";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import { cameraActions } from "../store/camera-slice";
import cx from 'classnames'
import ReactPlayer from "react-player";
import { DefaultEnv } from "./Endpoints";
import ImageMessage from "../components/Helpers/ImageMessage";

const Camera: React.FC = () => {
    const selectedJunction: any = useSelector((state: RootState) => state.junctions.selectedJunction);

    const dispatch = useDispatch();
    let param = useParams<{ camera_id: string }>();

    const history = useHistory();
    console.log(param)

    const player: any = useRef();
    const [page, setPage] = useState('videos');

    useEffect(() => {

        if (page === 'videos') {
            console.log('Get Camera data')
            dispatch(getVideoData(param['camera_id'], ''))
        }
        else {
            console.log('Get all data')
            dispatch(getAllData(param['camera_id']))
        }

        return () => {
            dispatch(cameraActions.setAllData({}))
            dispatch(cameraActions.setGraphData({}))
        }

    }, [page])

    const [timeline, setTimeline] = useState('');
    const [cameraName, setCamerName] = useState('');
    const [mainUrl, setMainUrl] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substr(0, 10));
    const [playing, setPlaying] = useState(false);
    const [videoPage, setVideoPage] = useState('bbox');

    const allData: any = useSelector((state: RootState) => state.camera.allData);
    const videoData: any = useSelector((state: RootState) => state.camera.videoData);
    const cameraError: any = useSelector((state: RootState) => state.camera.cameraError);
    const bboxData: any = useSelector((state: RootState) => state.camera.bboxData);
    const graphData: any = useSelector((state: RootState) => state.camera.graphData);

    let contentPage;
    let graphToggle;
    let videoToggle;
    let mainPanel;

    if (cameraError) {
        dispatch(cameraActions.setCameraError(false));
        history.push('/')
    }

    useEffect(() => {
        if (videoData.length > 0) {
            setMainUrl(videoData[videoData.length - 1].url);
            setCamerName(videoData[videoData.length - 1].camera_name)
        }
    }, [videoData])

    useEffect(() => {
        setPlaying(false);
    }, [mainUrl])

    useEffect(() => {
        console.log(allData, timeline)
        dispatch(getGraphData(allData, timeline))
    }, [allData, timeline])

    const [vehicleCount, setVehicleCount] = useState(0);
    const [avgWaitingTimeCount, setAvgWaitingTime] = useState(0);
    const [trafficJamCount, setTrafficJamCount] = useState(0);

    useMemo(() => {
        if (graphData && graphData.vehicle_count?.length > 0) {
            let vehicle_count = graphData.vehicle_count.reduce((sum: Number, data: any) => sum + data[1], 0);
            setVehicleCount(vehicle_count);
        }
        if (graphData && graphData.average_waiting_time?.length > 0) {
            let average_waiting_time = graphData.average_waiting_time.reduce((sum: Number, data: any) => sum + data[1], 0);
            setAvgWaitingTime(average_waiting_time);
        }
        if (graphData && graphData.traffic_jam_count?.length > 0) {
            let traffic_jam_count = graphData.traffic_jam_count.reduce((sum: Number, data: any) => sum + data[1], 0);
            setTrafficJamCount(traffic_jam_count);
        }
    }, [graphData])


    if (page === 'graph') {

        mainPanel = <TimeseriesGraph></TimeseriesGraph>

        graphToggle = cx(classes.Toggle, classes.SelectedToggle)
        videoToggle = cx(classes.Toggle, classes.UnselectedToggle)

        contentPage = (
            <div>
                <div className={classes.DateFilter}>Select Date Range</div>
                <div className={classes.Buttons}>
                    <div className={classes.Button}
                        onClick={() => {
                            setTimeline('1_day')
                        }}
                    >
                        1 Day
                    </div>
                    <div className={classes.Button}
                        onClick={() => {

                            setTimeline('1_week')
                        }}
                    >
                        1 Week
                    </div>
                    <div className={classes.Button}
                        onClick={() => {
                            setTimeline('15_days')
                        }}
                    >
                        15 Days
                    </div>
                </div>
                <div>
                    <Grid container>
                        <Grid item xs={12} className={classes.countHeader}>
                            Total Vehicle Count
                        </Grid>
                        <Grid item xs={12} className={classes.count}>
                            {vehicleCount}
                        </Grid>
                        <Grid item xs={12} className={classes.countHeader}>
                            Total Avarage Waiting Time
                        </Grid>
                        <Grid item xs={12} className={classes.count}>
                            {avgWaitingTimeCount}
                        </Grid>
                        <Grid item xs={12} className={classes.countHeader}>
                            Total Traffic Jam Count
                        </Grid>
                        <Grid item xs={12} className={classes.count}>
                            {trafficJamCount}
                        </Grid>
                    </Grid>
                </div>


            </div>
        )
    }


    else {

        if (videoPage === 'bbox') {

            contentPage = <div className={classes.VideoOptions}>
                <div className={classes.Label}>Select video type</div>

                <select className={classes.HourInput} value={videoPage} onChange={(e) => { setVideoPage(e.target.value) }}>
                    <option value='bbox'>Videos with tracking</option>
                    <option value='recorded'>Surveillance videos</option>
                </select>
            </div>

            if (bboxData.url != undefined) {
                mainPanel = <ReactPlayer
                    ref={player}
                    url={DefaultEnv.RtspUrl + bboxData.url}
                    muted={true}
                    width="100%"
                    height="100%"
                    controls={true}
                    onError={e => console.log('onError', e)}
                    onPlay={() => {
                        if (player.current.getDuration() > 120 && playing === false) {
                            player.current.seekTo(0)
                            setPlaying(true);
                        }
                    }}
                    onBuffer={() => {

                        if (player.current.getDuration() - player.current.getSecondsLoaded() < 5) {
                            player.current.seekTo(0)
                        }
                    }}
                ></ReactPlayer>
            }
            else {
                mainPanel = <div
                    style={{ color: "white", paddingTop: "25%", paddingLeft: "40%", fontFamily: "Roboto" }}
                >No bbox video available</div>
            }

        }
        else {
            contentPage = <div className={classes.VideoOptions}>
                <div className={classes.Label}>Select video type</div>

                <select className={classes.HourInput} value={videoPage} onChange={(e) => { setVideoPage(e.target.value) }}>
                    <option value='bbox'>Videos with tracking</option>
                    <option value='recorded'>Surveillance videos</option>
                </select>

                <div className={classes.Label}>Select Date</div>

                <input className={classes.DateInput} type="date" name="Date" defaultValue={selectedDate} onChange={(e) => {
                    dispatch(getVideoData(param['camera_id'], e.target.value))
                }}></input>

                <div className={classes.Label}>Select Hour</div>
                <select className={classes.HourInput} value={mainUrl} onChange={(e) => { setMainUrl(e.target.value) }}>
                    {videoData.map((row: any) => (
                        <option key={row.url} value={row.url}>{row.hour}</option>
                    ))}
                </select>
            </div>

            if (videoData.length > 0) {
                mainPanel = <ReactPlayer
                    ref={player}
                    url={DefaultEnv.RtspUrl + mainUrl}
                    muted={true}
                    width="100%"
                    height="100%"
                    controls={true}
                    onError={e => console.log('onError', e)}
                    onPlay={() => {
                        if (player.current.getDuration() > 120 && playing === false) {
                            player.current.seekTo(0)
                            setPlaying(true);
                        }
                    }}
                    onBuffer={() => {

                        if (player.current.getDuration() - player.current.getSecondsLoaded() < 5) {
                            player.current.seekTo(0)
                        }
                    }}
                ></ReactPlayer>
            }
            else {
                mainPanel = <div
                    style={{ color: "white", paddingTop: "25%", paddingLeft: "30%", fontFamily: "Roboto" }}
                >Videos not available for the selected date</div>
            }
        }
        videoToggle = cx(classes.Toggle, classes.SelectedToggle)
        graphToggle = cx(classes.Toggle, classes.UnselectedToggle)
    }

    const selectedJunctionDiv = <ImageMessage
        messageIcon={""}
        messageText={cameraName}
        next={false}
    ></ImageMessage>

    return <Layout>
        <div className={classes.Camera} >
            <div className={classes.Main}>
                {mainPanel}
            </div>
            <div className={classes.SidePanel}>
                <div className={classes.Hearder}>
                    <div className={classes.Heading}>
                        {selectedJunctionDiv}
                    </div>
                    <div className={classes.Clear}>
                        <Icon
                            onClick={() => {
                                history.goBack();
                            }}
                            className={classes.ClearIcon}
                            style={{ fontSize: "2.3vh", fontWeight: "bold" }}
                        >clear</Icon>
                    </div>
                </div>
                <div className={classes.Row1}>
                    <div className={classes.Toggles}>
                        <div className={graphToggle} onClick={() => { setPage('graph') }}>Graph</div>
                        <div className={videoToggle} onClick={() => { setPage('videos') }}>Videos</div>
                    </div>
                    <div className={classes.Content}>
                        {contentPage}
                    </div>
                </div>

            </div>
        </div >
    </Layout >
}

export default Camera;
