import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const videoStreamSlice = createSlice({
    name: "traffic",
    initialState: {
        streamUrls: [],
    },
    reducers: {
        setStreamUrls(state, action) {
            state.streamUrls = action.payload;
        }
    },
});

export const videoStreamActions = videoStreamSlice.actions;
export default videoStreamSlice;
