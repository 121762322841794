import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../store/index'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import classes from "./TrafficJams.module.css";
import IconButton from "@material-ui/core/IconButton";
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router-dom";



const TrafficJams: React.FC = () => {
  const history = useHistory();
  const [hasTrafficJam, setTrafficJam] = useState(false)
  // const Theme = useSelector((state: RootState) => state.theme.Theme);
  const TrafficJamData: any = useSelector((state: RootState) => state.traffic?.trafficJam);
  let existingArr: object[] = []

  useEffect(() => {
    let status = TrafficJamData?.status ?? false;
    setTrafficJam(status)
  }, [TrafficJamData])

  return (
    <React.Fragment>
      {hasTrafficJam &&
        <Card className={classes.RootJamCard}>
          <CardHeader
            title={"Traffic jam detected"}
            action={
              <IconButton aria-label="settings" onClick={() => setTrafficJam(false)}>
                <HighlightOff />
              </IconButton>
            }
            className={classes.JamHeader}
          />
          <CardContent className={classes.jamContainer}>
            {TrafficJamData?.data &&
              <List dense={true}>{TrafficJamData?.data.map((junction: any, index: number) => {
                if (!existingArr.some((data: any) => data.junctionId === junction.junction?.id && data.cameraId === junction.camera?.id)) {
                  existingArr.push({ junctionId: junction.junction?.id, cameraId: junction.camera.id })
                  return (<React.Fragment>
                    <ListItem button onClick={() => history.push("/junction/" + junction.junction?.id)}>
                      <ListItemText
                        className={classes.listText}
                        primary={`Junction Name: ${junction.junction.name}`}
                        secondary={`Camera Name: ${junction.camera.name}`}
                      />
                    </ListItem>
                    {TrafficJamData?.data?.length !== index + 1 && <Divider />}
                  </React.Fragment>
                  )
                }
              })}
              </List>
            }

          </CardContent>
        </Card>
      }
    </React.Fragment>
  );
};

export default TrafficJams;