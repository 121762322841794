import { authRequest } from "../containers/Auth"
import { DefaultEnv } from "../containers/Endpoints";
import { cameraActions } from "./camera-slice";

export const getAllData = (camera: string) => {

    return (dispatch: any) => {

        const endpoint = DefaultEnv.CameraGraph
        let params;

        params = {
            camera: camera,
            time_frame: "15_days"
        }

        authRequest.get(endpoint, { params: params })
            .then((response) => {

                console.log(response.data)

                dispatch(cameraActions.setAllData(response.data['data']))
                dispatch(cameraActions.setGraphData(response.data['data']))

            })
            .catch((error) => {
                console.log('Error')
                console.log(error.response)
                dispatch(cameraActions.setCameraError(true))
            })
    }
}


export const getGraphData = (allData: any, time_frame: string) => {

    return (dispatch: any) => {

        if (Object.keys(allData).length > 0) {

            let start;
            let end;

            if (time_frame === '1_day') {
                start = allData['vehicle_count'].length - (allData['vehicle_count'].length % 24);
                end = allData['vehicle_count'].length;
            }
            else if (time_frame === '1_week') {
                start = allData['vehicle_count'].length - (allData['vehicle_count'].length % 24) - (6 * 24);
                end = allData['vehicle_count'].length;
            }
            else {
                start = 0;
                end = allData['vehicle_count'].length;
            }

            dispatch(cameraActions.setGraphData({
                'vehicle_count': allData['vehicle_count'].slice(start, end),
                'average_waiting_time': allData['average_waiting_time'].slice(start, end),
                'traffic_jam_count': allData['traffic_jam_count'].slice(start, end),
            }))
        }

    }
}


export const getVideoData = (camera: string, date: string) => {


    return (dispatch: any) => {

        let params;
        const endpoint = DefaultEnv.CameraVideo

        if (date === '') {
            params = {
                camera: camera,
            }
        }
        else {
            params = {
                camera: camera,
                date: date
            }
        }

        authRequest.get(endpoint, { params: params })
            .then((response) => {
                console.log(response.data)
                dispatch(cameraActions.setVideoData(response.data['recorded']))
                dispatch(cameraActions.setBboxData(response.data['bbox']))
            })
            .catch((error) => {
                console.log('Error')
                console.log(error.response)
                dispatch(cameraActions.setCameraError(true))
            })
    }
}
