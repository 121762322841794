import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import themeSlice from "./theme-slice";

import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import trafficSlice from "./traffic-slice";
import devicesSlice from "./devices-slice";
import junctionsSlice from "./junctions-slice";
import jurisdictionsSlice from "./jurisdictions-slice";
import mapSlice from "./map-slice";
import graphSlice from "./graph-slice";
import videoStreamSlice from "./videoStream-slice";
import cameraSlice from "./camera-slice";
// import signalTimeSlice from "./signalTime-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    theme: themeSlice.reducer,
    traffic: trafficSlice.reducer,
    devices: devicesSlice.reducer,
    junctions: junctionsSlice.reducer,
    jurisdictions: jurisdictionsSlice.reducer,
    map: mapSlice.reducer,
    graph: graphSlice.reducer,
    videoStream: videoStreamSlice.reducer,
    camera: cameraSlice.reducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
