import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    devices: {},
  },
  reducers: {
    setDevices(state, action) {
      state.devices = action.payload;
    },
  },
});

export const devicesActions = devicesSlice.actions;
export default devicesSlice;
