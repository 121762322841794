import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const cameraSlice = createSlice({
    name: "camera",
    initialState: {
        graphData: {},
        allData: {},
        videoData: [],
        cameraError: false,
        bboxData: {},
    },
    reducers: {
        setGraphData(state, action) {
            state.graphData = action.payload;
        },
        setAllData(state, action) {
            state.allData = action.payload;
        },
        setVideoData(state, action) {
            state.videoData = action.payload;
        },
        setCameraError(state, action) {
            state.cameraError = action.payload;
        },
        setBboxData(state, action) {
            state.bboxData = action.payload;
        },
        
    },
});

export const cameraActions = cameraSlice.actions;
export default cameraSlice;
