import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
    name: "map",
    initialState: {
        mapData: {
            center: [20.5937, 78.9629],
            zoom: 3
        },
        markers: [],
        polygons: []
    },
    reducers: {
        setMapData(state, action) {
            state.mapData = action.payload;
        },
        setMarkers(state, action) {
            state.markers = action.payload;
        },
        setPolygons(state, action) {
            state.polygons = action.payload;
        },

    },
});

export const mapActions = mapSlice.actions;
export default mapSlice;
