import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import {
  Provider as AlertProvider,
  AlertProviderProps,
  transitions,
  positions,
  types
} from 'react-alert';

// const customContext = React.createContext<AlertManager | undefined>(undefined);

const AlertTemplate = (props: any) => {
  const { message } = props;
  return (
    <div style={{
      backgroundColor: "#526788",
      color: "white",
      height: '40px',
      width: '200px',
      padding: '10px',
      marginLeft: '20px',
      marginTop: '20px',
      borderRadius: '2%'
    }}>
      {message}
    </div>
  );
}


const options: AlertProviderProps = {
  position: positions.TOP_LEFT,
  timeout: 5000,
  offset: "18px",
  type: types.ERROR,
  transition: transitions.FADE,
  template: AlertTemplate,
};

ReactDOM.render(
  <AlertProvider {...options}>
    <App />
  </AlertProvider>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
