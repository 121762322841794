import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
    name: "theme",
    initialState: {
        Theme: "dark",
    },
    reducers: {
        setTheme(state, action) {
            state.Theme = action.payload;
        },
    },
});

export const themeActions = themeSlice.actions;
export default themeSlice;
