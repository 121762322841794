let RtspUrl;

if (window.location.origin === 'http://127.0.0.1:8000') {
  RtspUrl = 'https://eflag.in'
}
else {
  RtspUrl = "https://eflag.in"
}

export const DefaultEnv = {
  ServerUrl: 'https://eflag.in',
  RtspUrl: RtspUrl,
  Vehicles: "/api/traffic/list_vehicles",
  TrafficData: "/api/traffic/count_traffic",
  SignalTime: "/api/traffic/get_signal_time",
  DeviceData: "/api/devices/list_devices",
  JunctionData: "/api/junctions/list_junctions",
  JurisdictionData: "/api/jurisdictions/list_jurisdictions",
  AuthAccess: "/api/auth/access",
  AuthRefresh: "/api/auth/refresh",
  UserPing: "/api/users/ping",
  GraphData: "/api/traffic/graph_count",
  VideoStreams: "/api/cameras/list_video_streams",
  CameraGraph: "/api/cameras/camera_graph_api",
  CameraVideo: "/api/cameras/video_streams",
  JunctionPolygons: "/api/junctions/traffic_trend",
  trafficVehiclesList: "/api/traffic/list_vehicles"
};
